@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/** @format */

body {
  overflow-x: hidden !important;
  transition: all 200ms linear !important;
  box-sizing: border-box !important;
  width: 100% !important;
  scroll-behavior: smooth !important;
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
  font-family: "Poppins", sans-serif !important;
  position: relative;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */
  background: -o-linear-gradient(
    0deg,
    #85f6b4 0%,
    #85f6b4 0%,
    rgba(255, 255, 255, 1) 51%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CCF3FF', endColorstr='#FFFFFF', GradientType=1 );

  /* ie 10+ */

  /* global 94%+ browsers support */
  background: linear-gradient(
    0deg,
    #85f6b4 0%,
    #85f6b4 0%,
    rgba(255, 255, 255, 1) 51%
  );
  padding-bottom: 100px;
}

/* width */

::selection {
  background: #a2f6c5;
  color: #fff;
  text-shadow: none;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #20ed9b;
}

::-webkit-scrollbar-thumb:hover {
  background: #35e39d;
}
/** 
 *  <!-- --------***********************---------- -->
 *  <!--                 Navbar                    -->
 *  <!-- -------***********************---------- -->
 */
.navbar {
  transition: all 0.5s !important;
  text-align: center !important;
  width: 100% !important;
  background-color: white !important;
  box-shadow: 0px 7px 15px -12px rgb(35 35 35 / 15%) !important;
}
.navbar-brand {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  transition: all 0.3s ease-out;
}

.navbar-toggler {
  float: right !important;
  border: none !important;
  padding-right: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}
.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
}
.navbar-dark .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important ;
  position: relative !important;
  border-bottom: 1px solid #000 !important;
  transition: all 300ms linear !important;
}
.navbar-dark .navbar-toggler-icon:after,
.navbar-dark .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0 !important;
  left: 0 !important;
  content: "" !important;
  z-index: 2 !important;
  transition: all 300ms linear !important;
}
.navbar-dark .navbar-toggler-icon:after {
  top: 8px !important;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  -webkit-transform: rotate(45deg) !important;
          transform: rotate(45deg) !important;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  -webkit-transform: translateY(8px) rotate(-45deg) !important;
          transform: translateY(8px) rotate(-45deg) !important;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}
.nav-link {
  position: relative !important;
  padding: 8px 0 !important;
  display: inline-block !important;
  color: #4f5b6d !important;
  transition: all 200ms linear !important;
  font-size: 12pt !important;
  font-weight: 600 !important;
  text-align: center !important;
  cursor: pointer;
}
.nav-item > .active {
  color: #35e39d !important;
}
.nav-item {
  text-align: center !important;
  margin: 18px !important;
}
.nav-link:hover {
  color: #35e39d !important;
}
.nav-item:hover:after {
  bottom: 0 !important;
  opacity: 1 !important;
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}
.navbar-nav {
  margin-left: 240px !important;
}
.nav-btn {
  display: -webkit-flex !important;
  display: flex !important;
  padding: 12px !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  color: black !important;
  font-size: 14px !important;
  background-color: white !important;
  border-radius: 20px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  display: none !important;
}
.nav-btn {
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  color: #35e39d !important;
  font-weight: 400 !important;
  border: 1px solid #35e39d !important;
  border-radius: 5px !important ;
  padding: 10px 25px !important;
  display: none !important;
}
.nav-btn-m {
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  color: #35e39d !important;
  font-weight: 400 !important;
  border: 1px solid #35e39d !important;
  border-radius: 5px !important ;
  padding: 10px 25px !important;
}
.nav-btn-m:hover {
  background-color: #35e39d !important;
  color: white !important;
}
/** 
 *  <!-- --------***********************---------- -->
 *  <!--                 Heading                    -->
 *  <!-- -------***********************---------- -->
 */
.heading h1 {
  font-size: 50px !important;
  letter-spacing: 0 !important;
  color: #000 !important;
  font-weight: 700 !important;
}
.heading h4 {
  font-size: 20px !important;
  color: #4f5b6d !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
}
/** 
 *  <!-- --------***********************---------- -->
 *  <!--                 Accordion                -->
 *  <!-- -------***********************---------- -->
 */
.accordion-item {
  width: 80% !important;
  margin: 0 auto !important;
  /* background-color: white !important; */
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}
.accordion-btn span.d-block {
  color: #616161;
}
.accordion-btn:hover span.d-block,
.accordion-btn:focus span.d-block {
  color: white;
}
.accordion-btn {
  width: 100% !important;
  /* background-color: #fff !important; */
  background: url("https://app.unicrypt.network/img/blocks.ee90c33b.svg") #fff !important;
  background-size: cover !important;
  background-position: 50% !important;
  margin: 0 auto !important;
  height: 80px !important;
  border-radius: 3px !important;
  color: #000 !important;
  width: 100% !important;
  font-size: 20px !important;
  box-shadow: 0 0 20px -6px #e0e0e0 !important;
  outline: none !important;
  border: none !important;
  font-weight: 600 !important;
  margin-top: 20px !important;
}
.accordion-btn:hover,
.accordion-btn:focus {
  background-color: #35e39d !important;
  color: white !important;
}
.accordion-body {
  background-color: #fff !important;
  border-radius: 3px !important;
  margin-top: 10px !important;
}
.accordion-body ul li {
  font-size: 15pt !important;
}
.accordion-body a.submit-btn {
  margin: 0 auto !important;
  cursor: pointer !important;
  border: none !important;
  position: relative;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 25px;
  color: #fff;
  background-color: #35e39d;
  padding: 10px 30px;
  border-radius: 5px;
  font-weight: 400;
  display: block;
  text-decoration: none;
  text-align: center;
  width: 20%;
}
.accordion-body button i {
  margin-left: 13px;
  margin-left: 13px;
  position: relative;
  top: 2px;
}
.accordion-body button:hover {
  background-color: #35e39d;
  box-shadow: -1.717px 8.835px 19.32px 1.68px rgba(187, 237, 252, 0.004);
}
/** 
 *  <!-- --------***********************---------- -->
 *  <!--               Media query                 -->
 *  <!-- -------***********************---------- -->
 */
@media only screen and (max-width: 600px) {
  .nav-btn {
    display: block !important;
    width: 100% !important;
  }
  .nav-btn-m {
    display: none !important;
  }
  .navbar-nav {
    margin-left: 0px !important;
  }
}

